import cn from "classnames";

export interface IEmailIconProps {
  className?: string;
}

export default function EmailIcon(props: IEmailIconProps) {
  const { className } = props;

  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M13.1667 0.916687H4.83341C2.33341 0.916687 0.666748 2.16669 0.666748 5.08335V10.9167C0.666748 13.8334 2.33341 15.0834 4.83341 15.0834H13.1667C15.6667 15.0834 17.3334 13.8334 17.3334 10.9167V5.08335C17.3334 2.16669 15.6667 0.916687 13.1667 0.916687ZM13.5584 5.99169L10.9501 8.07502C10.4001 8.51669 9.70008 8.73335 9.00008 8.73335C8.30008 8.73335 7.59175 8.51669 7.05008 8.07502L4.44175 5.99169C4.17508 5.77502 4.13341 5.37502 4.34175 5.10835C4.55841 4.84169 4.95008 4.79169 5.21675 5.00835L7.82508 7.09169C8.45841 7.60002 9.53341 7.60002 10.1667 7.09169L12.7751 5.00835C13.0417 4.79169 13.4417 4.83335 13.6501 5.10835C13.8667 5.37502 13.8251 5.77502 13.5584 5.99169Z"
        fill="#292D32"
        style={{
          fill: "#292D32",
          fillOpacity: 1,
          color: "display-p3 0.1608 0.1765 0.1961",
        }}
      />
    </svg>
  );
}
