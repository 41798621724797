export default function ArrowRightIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4854 8.88875C16.8759 8.49822 16.8759 7.86506 16.4854 7.47453L10.1214 1.11057C9.7309 0.720048 9.09773 0.720048 8.70721 1.11057C8.31668 1.5011 8.31668 2.13426 8.70721 2.52479L14.3641 8.18164L8.70721 13.8385C8.31668 14.229 8.31668 14.8622 8.70721 15.2527C9.09773 15.6432 9.7309 15.6432 10.1214 15.2527L16.4854 8.88875ZM0.221924 9.18164H15.7783V7.18164L0.221924 7.18164L0.221924 9.18164Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
}
