import cn from "classnames";
export interface IDPFLogoProps {
  className?: string;
}

export default function DPFLogo(props: IDPFLogoProps) {
  const { className } = props;

  return (
    <svg
      width="96"
      height="45"
      viewBox="0 0 96 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <g clip-path="url(#clip0_70_180)">
        <path
          d="M31.1507 22.3062C31.1507 34.6644 22.252 43.9702 9.99514 44.4267H0.720371C0.323107 44.4267 0 44.1029 0 43.7047V35.6305C0 35.2324 0.323107 34.9086 0.720371 34.9086H10.0216C15.4244 34.5158 21.2986 30.407 21.2986 22.3009C21.2986 14.1949 15.5833 9.64551 9.92099 9.2633H0.720371C0.323107 9.2633 0 8.93948 0 8.54666V0.716645C0 0.323817 0.323107 0 0.720371 0H9.94747C22.2467 0.429987 31.156 9.81007 31.156 22.3062H31.1507Z"
          fill="#1159E4"
          style={{
            fill: "#1159E4",
            fillOpacity: 1,
            color: "display-p3 0.0670 0.3482 0.8940",
          }}
        />
        <path
          d="M96 1.74649V9.34293C96 9.74106 95.6769 10.0649 95.2797 10.0649H69.251C68.8537 10.0649 68.5359 9.74106 68.5359 9.34293V1.74649C68.5359 1.34835 68.859 1.02454 69.251 1.02454H95.2797C95.6769 1.02454 96 1.34835 96 1.74649ZM93.8336 19.8166V27.0467C93.8336 27.4449 93.5105 27.7634 93.1185 27.7634H78.0914V43.004C78.0914 43.4021 77.7683 43.7207 77.371 43.7207H69.251C68.8537 43.7207 68.5359 43.3968 68.5359 43.004V19.8113C68.5359 19.4131 68.859 19.0893 69.251 19.0893H93.1185C93.5158 19.0893 93.8336 19.4078 93.8336 19.8113V19.8166Z"
          fill="#70EEFF"
          style={{
            fill: "#70EEFF",
            fillOpacity: 1,
            color: "display-p3 0.4393 0.9327 1.0000",
          }}
        />
        <path
          d="M64.4839 15.9679C64.4839 24.1483 58.615 29.6373 49.8805 29.6373H43.3283V44.2781C43.3283 44.6762 43.0052 45 42.6132 45H34.5514C34.1542 45 33.8311 44.6762 33.8311 44.2781V22.1682C33.8311 21.7701 34.1542 21.4516 34.5514 21.4516H48.9165C52.6031 21.4516 54.9866 19.3229 54.9866 16.0316C54.9866 12.7404 52.6613 10.548 48.9165 10.548H44.1069C43.7097 10.548 43.3866 10.2241 43.3866 9.82601V3.02054C43.3866 2.6224 43.7097 2.29858 44.1069 2.29858H49.8805C58.4773 2.29858 64.4839 7.92027 64.4839 15.9679Z"
          fill="#30C1FF"
          style={{
            fill: "#30C1FF",
            fillOpacity: 1,
            color: "display-p3 0.1875 0.7562 1.0000",
          }}
        />
      </g>
      <defs>
        <clipPath id="clip0_70_180">
          <rect
            width="96"
            height="45"
            fill="white"
            style={{ fill: "white", fillOpacity: 1 }}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
