export default function ArrowDownRightIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5L13 3.5C13 2.94772 12.5523 2.5 12 2.5C11.4477 2.5 11 2.94772 11 3.5L11 11.5L3 11.5C2.44772 11.5 2 11.9477 2 12.5C2 13.0523 2.44772 13.5 3 13.5L12 13.5ZM0.292893 2.20711L11.2929 13.2071L12.7071 11.7929L1.70711 0.792893L0.292893 2.20711Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
}
