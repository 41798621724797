export default function MobilePaymentIcon() {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_70_61)">
        <path
          d="M45.9167 76H11.0834C8.9843 75.9983 6.97164 75.1637 5.48734 73.6794C4.00304 72.1951 3.16843 70.1824 3.16675 68.0833V7.91667C3.16675 3.54983 6.71658 0 11.0834 0H45.9167C50.2836 0 53.8334 3.54983 53.8334 7.91667C53.8334 8.33659 53.6666 8.73932 53.3697 9.03625C53.0727 9.33319 52.67 9.5 52.2501 9.5C51.8302 9.5 51.4274 9.33319 51.1305 9.03625C50.8336 8.73932 50.6667 8.33659 50.6667 7.91667C50.6667 5.29783 48.5356 3.16667 45.9167 3.16667H11.0834C8.46458 3.16667 6.33341 5.29783 6.33341 7.91667V68.0833C6.33341 70.7022 8.46458 72.8333 11.0834 72.8333H45.9167C48.5356 72.8333 50.6667 70.7022 50.6667 68.0833C50.6667 67.6634 50.8336 67.2607 51.1305 66.9637C51.4274 66.6668 51.8302 66.5 52.2501 66.5C52.67 66.5 53.0727 66.6668 53.3697 66.9637C53.6666 67.2607 53.8334 67.6634 53.8334 68.0833C53.8334 72.4502 50.2836 76 45.9167 76Z"
          fill="#70EEFF"
          style={{
            fill: "#70EEFF",
            fillOpacity: 1,
            color: "display-p3 0.4393 0.9327 1.0000",
          }}
        />
        <path
          d="M34.8335 9.5H22.1668C19.548 9.5 17.4168 7.36883 17.4168 4.75C17.4168 3.87917 16.7043 3.16667 15.8335 3.16667H12.6668C12.2469 3.16667 11.8442 2.99985 11.5472 2.70292C11.2503 2.40599 11.0835 2.00326 11.0835 1.58333C11.0835 1.16341 11.2503 0.76068 11.5472 0.463748C11.8442 0.166815 12.2469 0 12.6668 0L15.8335 0C18.4523 0 20.5835 2.13117 20.5835 4.75C20.5835 5.62083 21.296 6.33333 22.1668 6.33333H34.8335C35.7043 6.33333 36.4168 5.62083 36.4168 4.75C36.4168 2.13117 38.548 0 41.1668 0H44.3335C44.7534 0 45.1562 0.166815 45.4531 0.463748C45.75 0.76068 45.9168 1.16341 45.9168 1.58333C45.9168 2.00326 45.75 2.40599 45.4531 2.70292C45.1562 2.99985 44.7534 3.16667 44.3335 3.16667H41.1668C40.296 3.16667 39.5835 3.87917 39.5835 4.75C39.5835 7.36883 37.4523 9.5 34.8335 9.5ZM50.6668 60.1667C38.4467 60.1667 28.5002 50.2202 28.5002 38C28.5002 25.7798 38.4467 15.8333 50.6668 15.8333C62.887 15.8333 72.8335 25.7798 72.8335 38C72.8335 50.2202 62.887 60.1667 50.6668 60.1667ZM50.6668 19C40.1883 19 31.6668 27.5215 31.6668 38C31.6668 48.4785 40.1883 57 50.6668 57C61.1453 57 69.6668 48.4785 69.6668 38C69.6668 27.5215 61.1453 19 50.6668 19Z"
          fill="#70EEFF"
          style={{
            fill: "#70EEFF",
            fillOpacity: 1,
            color: "display-p3 0.4393 0.9327 1.0000",
          }}
        />
        <path
          d="M52.25 49.0833H44.3333C43.9134 49.0833 43.5107 48.9165 43.2137 48.6196C42.9168 48.3227 42.75 47.9199 42.75 47.5C42.75 47.0801 42.9168 46.6774 43.2137 46.3804C43.5107 46.0835 43.9134 45.9167 44.3333 45.9167H52.25C53.0899 45.9167 53.8953 45.583 54.4892 44.9892C55.083 44.3953 55.4167 43.5899 55.4167 42.75C55.4167 41.9102 55.083 41.1047 54.4892 40.5108C53.8953 39.917 53.0899 39.5833 52.25 39.5833H49.0833C45.5905 39.5833 42.75 36.7428 42.75 33.25C42.75 29.7572 45.5905 26.9167 49.0833 26.9167H57C57.4199 26.9167 57.8227 27.0835 58.1196 27.3804C58.4165 27.6774 58.5833 28.0801 58.5833 28.5C58.5833 28.9199 58.4165 29.3227 58.1196 29.6196C57.8227 29.9165 57.4199 30.0833 57 30.0833H49.0833C48.2435 30.0833 47.438 30.417 46.8442 31.0108C46.2503 31.6047 45.9167 32.4102 45.9167 33.25C45.9167 34.0899 46.2503 34.8953 46.8442 35.4892C47.438 36.083 48.2435 36.4167 49.0833 36.4167H52.25C55.7428 36.4167 58.5833 39.2572 58.5833 42.75C58.5833 46.2428 55.7428 49.0833 52.25 49.0833Z"
          fill="#70EEFF"
          style={{
            fill: "#70EEFF",
            fillOpacity: 1,
            color: "display-p3 0.4393 0.9327 1.0000",
          }}
        />
        <path
          d="M50.6668 53.8333C50.2469 53.8333 49.8442 53.6665 49.5472 53.3696C49.2503 53.0727 49.0835 52.6699 49.0835 52.25V47.5C49.0835 47.0801 49.2503 46.6774 49.5472 46.3804C49.8442 46.0835 50.2469 45.9167 50.6668 45.9167C51.0868 45.9167 51.4895 46.0835 51.7864 46.3804C52.0833 46.6774 52.2502 47.0801 52.2502 47.5V52.25C52.2502 52.6699 52.0833 53.0727 51.7864 53.3696C51.4895 53.6665 51.0868 53.8333 50.6668 53.8333ZM50.6668 30.0833C50.2469 30.0833 49.8442 29.9165 49.5472 29.6196C49.2503 29.3227 49.0835 28.9199 49.0835 28.5V23.75C49.0835 23.3301 49.2503 22.9274 49.5472 22.6304C49.8442 22.3335 50.2469 22.1667 50.6668 22.1667C51.0868 22.1667 51.4895 22.3335 51.7864 22.6304C52.0833 22.9274 52.2502 23.3301 52.2502 23.75V28.5C52.2502 28.9199 52.0833 29.3227 51.7864 29.6196C51.4895 29.9165 51.0868 30.0833 50.6668 30.0833Z"
          fill="#70EEFF"
          style={{
            fill: "#70EEFF",
            fillOpacity: 1,
            color: "display-p3 0.4393 0.9327 1.0000",
          }}
        />
      </g>
      <defs>
        <clipPath id="clip0_70_61">
          <rect
            width="76"
            height="76"
            fill="white"
            style={{ fill: "white", fillOpacity: 1 }}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
