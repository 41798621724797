import cn from "classnames";

export interface IMenuProps {
  className?: string;
}

const Menu = (props: IMenuProps) => {
  const { className } = props;

  return (
    <ul
      className={cn(
        "flex flex-row justify-evenly rounded-[57px] bg-[#FFFFFF] text-black",
        className
      )}
    >
      <li className="flex justify-center items-center font-semibold text-[20px] leading-6 cursor-pointer">
        <a href="#home">Home</a>
      </li>
      <li className="flex justify-center items-center font-semibold text-[20px] leading-6 cursor-pointer">
        <a href="#services">Services</a>
      </li>
      <li className="flex justify-center items-center font-semibold text-[20px] leading-6 cursor-pointer">
        <a href="#contact">Contact Us</a>
      </li>
    </ul>
  );
};

export default Menu;
