import cn from "classnames";

export interface IAddressIconProps {
  className?: string;
}

export default function AddressIcon(props: IAddressIconProps) {
  const { className } = props;

  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M15.1819 6.04165C14.3069 2.19165 10.9486 0.458313 7.99861 0.458313C7.99861 0.458313 7.99861 0.458313 7.99028 0.458313C5.04861 0.458313 1.68195 2.18331 0.806948 6.03331C-0.168052 10.3333 2.46528 13.975 4.84861 16.2666C5.73195 17.1166 6.86528 17.5416 7.99861 17.5416C9.13195 17.5416 10.2653 17.1166 11.1403 16.2666C13.5236 13.975 16.1569 10.3416 15.1819 6.04165ZM7.99861 10.2166C6.54861 10.2166 5.37361 9.04165 5.37361 7.59165C5.37361 6.14165 6.54861 4.96665 7.99861 4.96665C9.44861 4.96665 10.6236 6.14165 10.6236 7.59165C10.6236 9.04165 9.44861 10.2166 7.99861 10.2166Z"
        fill="black"
        style={{ fill: "black", fillOpacity: 1 }}
      />
    </svg>
  );
}
